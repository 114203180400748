import React, { useState } from 'react';

import { Menu } from './menu';

import { NavOverlay } from './menuStyle';
export interface IOverlayNav {
  isShown: boolean;
  onMouseOver: React.MouseEventHandler<HTMLDivElement>;
}

export const OverlayNav = ({ isShown, onMouseOver }: IOverlayNav) => {
  return (
    <>
      <NavOverlay onMouseOver={onMouseOver} isShown={isShown}></NavOverlay>
    </>
  );
};
